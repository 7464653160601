/* heading2-3:
 *
 * Turn first top label into heading level 2, and any subsequent to level 3.
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  var level = 2;

  $(function () {
    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.groups && data.groups.length) {
        var topdone = false;
        $.each(data.groups, function (_, group) {
          var ctl;
          if (
            group.controls &&
            ((ctl = group.controls[0]), ctl) &&
            ctl.controltype === "label" &&
            ctl["font-class"].toLowerCase().indexOf("title") > -1
          ) {
            ctl._subtype = "heading";
            ctl._level = topdone ? level + 1 : level;
            topdone = true;
          }
        });
      }
    });
  });
})(jQuery, window, document);
